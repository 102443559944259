.contactme {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-right: 5vw;
  border-radius: 20px;
}

form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

input {
  border-radius: 5px;
  width: 50vw;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 15px 15px rgba(0, 0, 0, 0.2);
  border-width: 2px;
  border-color: rgba(123, 154, 109, 0.7);
  outline: none;
}

input:focus {
  border-color: white;
}

textarea {
  border-radius: 5px;
  width: 50vw;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 15px 15px rgba(0, 0, 0, 0.2);
  border-width: 2px;
  border-color: rgba(123, 154, 109, 0.7);
  outline: none;
}

textarea:focus {
  border-color: white;
}

.submitbtn {
  background: rgba(123, 154, 109, 0.7);
  border-radius: 10px;
  border-width: 1px;
  border-color: rgba(255, 255, 255, 0.8);
  padding: 5px;
  font-size: 20px;
  width: 30vw;
}

.submitbtn:hover {
  background: rgba(123, 154, 109, 1);
  border-radius: 10px;
  border-width: 3px;
  border-color: rgba(255, 255, 255, 0.8);
  padding: 5px;
  font-weight: bold;
}
