@keyframes rotate-background {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);

    }
  }

  @keyframes rotate-background2 {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);

    }
  }

  
  
.bg-container{
  filter: brightness(100%);
  filter:blur(35px);
  border-radius: 750px;
  width:200%;

  top: 0;
  z-index: 1;
  animation: rotate-background 20s linear infinite;
}

 
.bg-container2{
  filter: brightness(100%);

  filter:blur(35px);

  top: 0;
  position: absolute;
  border-radius: 750px;
  width:200%;
  top: 0;
  z-index: 1;
  animation: rotate-background2 25s linear infinite;
}





