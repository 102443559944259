Header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
    height: 9vh;
	border-bottom: 1px solid #7b9a6d4d;
	z-index: 999;
}

.logofont { 
	font-family: Rockwell Extra Bold,Rockwell Bold,monospace; 
    color: white;
}

.logo{
    width: 26%;
   
}

.tab{
    font-weight: bold;
    font-size: 2.7vh;
    margin-left: 1vw;
    margin-right: 1vw;
}

.tab:hover{
    text-decoration-thickness: 3px;
    text-underline-offset: 4px;
    text-decoration-line: underline;
    text-decoration-color: #7b9a6d;

}





