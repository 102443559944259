.video{
    border-radius: 30px;
}

.ima{
    border-radius: 30px;
    width:80%;
}

.bio-card {
    border-top: 2px solid rgba(255,255,255,0.6);
    border-bottom: 2px solid rgba(255,255,255,0.6);

    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    width: 100%; 
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-direction: row;
}

.bio-content {
    font-weight: bold;
    font-size: 1.5rem;
    margin-bottom: 2rem;
    margin-top: 2rem;
}

.about-us-container {
    flex: column;
    justify-content: center;
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
    margin-top: 50px; 
    padding-left: 5vw;
    padding-right: 5vw;

}


