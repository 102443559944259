h2{
    margin-top: 30vh;
}

.homeBtn{
    margin-top: 50vh;
    padding: 8px;
    width: 10vw;
    border: 2px solid #7b9a6d; /* Green */
    border-radius: 5px;

}

.homeBtn:hover{
    background-color: rgba(123, 154, 109, 0.5);
    color: white;
    
}

.textContainer{
    position: absolute;
    width: 100%;
    z-index: 5;
    top: 0;
    margin-top: 100vh;

}
.textContainer2{
    position: absolute;
    width: 100%;
    z-index: 5;
    top: 0;
    margin-top: 200vh;

}