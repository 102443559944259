.emailsignup{
    margin-top: 25vh;
    background-color: rgba(0,0,0,0.5);
    width: 85%;
    border-radius: 30px;
    border-color: rgba(255,255,255,0.3);
    border-width: 2px;
    height: 55vh;
}





